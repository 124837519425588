import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  Table,
  Label
} from "reactstrap";

import NotificationAlert from "react-notification-alert";

import defaultService from "../../services/defaultService";
import ModalConfirm from "components/ModalConfirm";
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import Carteira from "components/Carteira";

const table = "carteiras";
function CarteiraScreen({ nameRoute }) {

  const [isOpen, setIsOpen] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [items, setUsers] = useState([]);
  const [socios, setSocios] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [localUser, setLocalUser] = useState({});
  const notificationAlert = React.useRef();


  useEffect(() => {
    listItems();
  }, []);

  const listItems = async () => {
    const localUser_ = JSON.parse(localStorage?.getItem("user"));
    setLocalUser(localUser_);

    var items_ = await defaultService.list(table);
    items_?.[0]?.id && setUsers(items_);

    var items_ = await defaultService.listSelect("socios");
    items_?.[0]?.id && setSocios(items_);
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    const form = {};

    form.table = table;

    for (let index = 0; index < e.target.length; index++) {
      if (e.target[index].name)
        form[e.target[index].name] = e.target[index].value;
    }

    if (currentItem?.id) {
      editItem(form);
      return;
    }

    const resp = await defaultService.create(form);
    setIsOpen(false);

    resp?.status == 200 ?
      notify("tr", 2, 'Cadastro feito com sucesso!')
      : notify("tr", 3, 'Erro ao cadastrar!');

    listItems();

  }

  const deleteItem = async (e) => {
    setModalConfirm(false);

    if (e != "sim") {
      setCurrentItem({});
      return
    }

    const resp = await defaultService.delete(currentItem.id, table);

    resp?.status == 200 ?
      notify("tr", 2, "Deletado com sucesso!")
      : notify("tr", 3, 'Erro ao deletar!');

    setCurrentItem({});

    listItems();
  }

  const editItem = async (userData) => {

    const resp = await defaultService.update(currentItem?.id, userData);
    resp?.status == 200 ?
      notify("tr", 2, "Atualizado com sucesso!")
      : notify("tr", 3, 'Erro ao atalizar!');

    setCurrentItem({});
    setIsOpen(false);

    listItems();
  }

  const notify = (place, color, message) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div><div>{message}</div></div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
      <div className="content">
        <NotificationAlert ref={notificationAlert} />
        <ModalConfirm isOpen={modalConfirm} toggle={deleteItem} />
        <Row>
          <Col md="12">
            <Modal
              size="xl"
              isOpen={isOpen}
              toggle={() => { setIsOpen(false); setCurrentItem({}) }} >
              <h6 className="text-right pr-5 mt-3" style={{ cursor: "pointer" }} onClick={() => { setIsOpen(false); setCurrentItem({}); }}>Fechar</h6>
              <Card className="card-user m-3" >
                <CardHeader>
                  <CardTitle tag="h5">Carteirinha</CardTitle>
                </CardHeader>
                <CardBody>

                  <Row>
                    <Col md="6">
                      <Form onSubmit={onSubmit} datatype="multipart/form-data">
                        <Input type="hidden" name="id" defaultValue={currentItem?.id} />

                        <Row>
                          <Col className="pr-1" md="12">
                            <FormGroup>
                              <label>Sindicalizado/Sócio</label>
                              <Input
                                type="select"
                                name="idSocio"
                                defaultValue={currentItem?.nome}
                                onChange={(e) => {
                                  setCurrentItem({
                                    ...currentItem,
                                    idSocio: e.target.value,
                                  });
                                }}
                              >
                                <option value="">Selecione</option>
                                {socios?.map((item) => (
                                  <option key={item.id + 'socio'} value={item.id}>
                                    {item.nome}
                                  </option>
                                ))}
                              </Input>
                            </FormGroup>
                          </Col>

                          <Col className="pr-1" md="12">
                            <FormGroup>
                              <label>Situação</label>
                              <Input
                                type="select"
                                name="situacao"
                                defaultValue={currentItem?.situacao}>
                                <option value="Pendente">Pendente</option>
                                <option value="Ativo">Ativo</option>
                                <option value="Desligado">Desligado</option>
                              </Input>
                            </FormGroup>
                          </Col>

                          <Col className="pr-1" md="12">
                            <FormGroup>
                              <label>Observação</label>
                              <Input
                                defaultValue={currentItem?.observacao}
                                type="text"
                                name="observacao"
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <div className="update ml-auto mr-auto">
                            <Button
                              className="btn-round"
                              color="primary"
                              type="submit"
                            >
                              {currentItem.id ? "Atualizar" : "Criar"}
                            </Button>
                          </div>
                        </Row>
                      </Form>
                    </Col>

                    <Col md="6">
                      <Carteira
                        {...{
                          nome: socios?.find(socio => socio.id == currentItem?.idSocio)?.nome ,
                          cpf: socios?.find(socio => socio.id == currentItem?.idSocio)?.cpf ,
                          rg: socios?.find(socio => socio.id == currentItem?.idSocio)?.rg ,
                          numero_socio: socios?.find(socio => socio.id == currentItem?.idSocio)?.numero_socio ,
                          telefone: socios?.find(socio => socio.id == currentItem?.idSocio)?.telefone ,
                          birthdate: "01/01/2000",
                          expiryDate: "01/01/2025"
                        }} />
                    </Col>
                  </Row>


                </CardBody>
              </Card>
            </Modal>
          </Col>

          <Col md="12">
            <Button
              className="btn-round"
              color="primary"
              onClick={setIsOpen}
            >
              Criar Novo
            </Button>
          </Col>

          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{nameRoute}</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Nome</th>
                      <th>Situação</th>
                      <th>Observação</th>
                      <th>Carteirinha</th>
                      <th className="text-right">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items && items.map((item) => (
                      <tr id={item.id + 'item1'}>
                        <td>{socios?.find(socio => socio.id == item.idSocio)?.nome}</td>
                        <td>{item.situacao}</td>
                        <td>{item.observacao}</td>
                        <td><a href="#" onClick={() => { setCurrentItem(item); setIsOpen(true); }}>visualizar carteirinha</a></td>
                        <td className="text-right">
                          <Button
                            color="primary"
                            round
                            className="mr-1"
                            placeholder="Editar"
                            onClick={() => { setCurrentItem(item); setIsOpen(true); }}>
                            <i className="fa fa-pen"></i>
                          </Button>
                          <Button
                            color="danger"
                            round
                            className="mr-1"
                            onClick={() => { setModalConfirm(true); setCurrentItem(item); }}>
                            <i className="fa fa-trash"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default CarteiraScreen;
