import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  Table,
  Label
} from "reactstrap";

import NotificationAlert from "react-notification-alert";

import defaultService from "../../services/defaultService";
import ModalConfirm from "components/ModalConfirm";
import ListadeParticipantesScreen from "views/ListadeParticipantesScreen";
import ApiCalendar from 'react-google-calendar-api';

const config = {
  "clientId": "1076445512781-1gnfdr4p19tfhj97o9jd8grrk2gdnk9v.apps.googleusercontent.com",
  "apiKey": "AIzaSyCQW0U8rFGPKSF-Le3Qo9ZLU9vsDT6NBfs",
  "scope": "https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.settings.readonly https://www.googleapis.com/auth/calendar.events https://www.googleapis.com/auth/calendar.events.readonly https://www.googleapis.com/auth/calendar.readonly",
  "discoveryDocs": [
    "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"
  ]
}

const apiCalendar = new ApiCalendar(config)

const table = "reunioes";
function VideoChamadaScreen({ nameRoute }) {

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenParticipantes, setIsOpenParticipantes] = useState(false);
  const [selectedReuniao, setSelectedReuniao] = useState({});
  const [modalConfirm, setModalConfirm] = useState(false);
  const [items, setUsers] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [localUser, setLocalUser] = useState({});
  const notificationAlert = React.useRef();
  const localUser_ = JSON.parse(localStorage?.getItem("user"));


  useEffect(() => {
    listItems();
  }, []);

  const listItems = async () => {
    const localUser_ = JSON.parse(localStorage?.getItem("user"));
    setLocalUser(localUser_);

    //var items_ = await defaultService.list(table);
    //items_?.[0]?.id && setUsers(items_);

    setTimeout(async () => {
      apiCalendar.onLoad(
        async () => {
          //const resp = await apiCalendar.handleAuthClick()
          let events = await apiCalendar.listEvents(10, 'empresasindicato17@gmail.com')
          var events_ = events?.result?.items.map(
            item => {
              return {
                nome: item?.summary ? item?.summary : 'titulo',
                tipo_evento: item?.description,
                data_evento: item?.start?.date || item?.start?.dateTime,
                url: item?.hangoutLink,
              }
            })

          setUsers(events_)
        }
      )
    }, 2000)

  }

  const onSubmit = async (e) => {
    e.preventDefault();

    const form = {};

    form.table = table;

    for (let index = 0; index < e.target.length; index++) {
      if (e.target[index].name)
        form[e.target[index].name] = e.target[index].value;
    }

    if (currentItem?.id) {
      editItem(form);
      return;
    }

    const resp = await defaultService.create(form);
    setIsOpen(false);

    resp?.status == 200 ?
      notify("tr", 2, 'Cadastro feito com sucesso!')
      : notify("tr", 3, 'Erro ao cadastrar!');

    listItems();

  }

  const deleteItem = async (e) => {
    setModalConfirm(false);

    if (e != "sim") {
      setCurrentItem({});
      return
    }

    await apiCalendar.deleteEvent(currentItem?.id, 'empresasindicato17@gmail.com')

    notify("tr", 2, "Deletado com sucesso!")

    setCurrentItem({});

    listItems();
  }

  const editItem = async (userData) => {

    const resp = await defaultService.update(currentItem?.id, userData);
    resp?.status == 200 ?
      notify("tr", 2, "Atualizado com sucesso!")
      : notify("tr", 3, 'Erro ao atalizar!');

    setCurrentItem({});
    setIsOpen(false);

    listItems();
  }

  const notify = (place, color, message) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div><div>{message}</div></div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
      <div className="content">
        <NotificationAlert ref={notificationAlert} />
        <ModalConfirm isOpen={modalConfirm} toggle={deleteItem} />
        <Row>
          <Col md="4">
            <Modal
              isOpen={isOpen}
              toggle={() => { setIsOpen(false); setCurrentItem({}) }} >
              <h6 className="text-right pr-5 mt-3" style={{ cursor: "pointer" }} onClick={() => { setIsOpen(false); setCurrentItem({}); }}>Fechar</h6>
              <Card className="card-user m-3" >
                <CardHeader>
                  <CardTitle tag="h5">Criar</CardTitle>
                </CardHeader>
                <CardBody>
                  <Form onSubmit={onSubmit} datatype="multipart/form-data">
                    <Input type="hidden" name="id" defaultValue={currentItem?.id} />
                    <Input type="hidden" name="room" defaultValue={currentItem?.id ? currentItem?.room : new Date().getTime()} />

                    <Row>
                      <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Título</label>
                          <Input
                            defaultValue={currentItem?.nome}
                            type="text"
                            name="nome"
                          />
                        </FormGroup>
                      </Col>

                      <Col className="pr-1" md="12">
                        <FormGroup>
                          <label>Descrição</label>
                          <Input
                            defaultValue={currentItem?.descricao}
                            type="text"
                            name="descricao"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label>Data do Evento</label>
                          <Input
                            defaultValue={currentItem?.data_evento}
                            type="date"
                            name="data_evento"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label>Tipo do Evento</label>
                          <Input
                            type="select"
                            name="tipo_evento"
                            defaultValue={currentItem?.tipo_evento}
                          >
                            <option value="Reunião">Reunião</option>
                            <option value="Assembléia">Assembléia</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="12">
                        <FormGroup>
                          <label>Local do Evento</label>
                          <Input
                            defaultValue={currentItem?.local}
                            placeholder=""
                            type="text"
                            name="local"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>Total de Participantes</label>
                          <Input
                            defaultValue={currentItem?.total_participantes}
                            placeholder=""
                            type="text"
                            name="total_participantes"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>Status do Evento</label>
                          <Input
                            type="select"
                            name="status_evento"
                            defaultValue={currentItem?.status_evento}
                          >
                            <option value="Aguardando">Aguardando</option>
                            <option value="Em Andamento">Em Andamento</option>
                            <option value="Finalizado">Finalizado</option>
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>Responsável</label>
                          <Input
                            defaultValue={currentItem?.responsavel}
                            placeholder=""
                            type="text"
                            name="responsavel"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <label>Privado</label>
                          <Input
                            defaultValue={currentItem?.privado}
                            placeholder=""
                            type="text"
                            name="privado"
                          />
                        </FormGroup>
                      </Col>

                      <Col md="6">
                        <FormGroup>
                          <label>Senha</label>
                          <Input
                            defaultValue={currentItem?.senha}
                            placeholder=""
                            type="text"
                            name="senha"
                          />
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row>
                      <div className="update ml-auto mr-auto">
                        <Button
                          className="btn-round"
                          color="primary"
                          type="submit"
                        >
                          {currentItem.id ? "Atualizar" : "Criar"}
                        </Button>
                      </div>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Modal>

            <Modal
              size="lg"
              isOpen={isOpenParticipantes}
              toggle={() => { setIsOpenParticipantes(false); }} >
              <h6 className="text-right pr-5 mt-3" style={{ cursor: "pointer" }} onClick={() => { setIsOpenParticipantes(false); }}>Fechar</h6>
              <ListadeParticipantesScreen reuniao={selectedReuniao} />
            </Modal>
          </Col>

          <Col md="12">
            <Button
              className="btn-round"
              color="primary"
              onClick={setIsOpen}
            >
              Criar Novo
            </Button>
          </Col>

          <Col md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h4">{nameRoute}</CardTitle>
              </CardHeader>
              <CardBody>
                <Table responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Nome</th>
                      <th>Data</th>
                      <th>Tipo</th>
                      {/*<th>Status</th>
                      <th>Participantes</th>
  <th>Ingressar</th>*/}
                      <th className="text-right">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items && items.map((item) => (
                      <tr id={item.id + 'item1'}>
                        <td>{item.nome}</td>
                        <td>{new Date(item.data_evento).toLocaleDateString('pt-BR')}</td>
                        <td>{item.tipo_evento}</td>
                        {/*<td>{item.status_evento}</td>
                        <td><a href="#" onClick={() => { setIsOpenParticipantes(true); setSelectedReuniao(item); }}>visualizar</a></td>
                        <td><a href={`https://assembleia.agenciaiter.com.br/?room=${item.room}&nome=${localUser_?.nome}&type=host`} target="_blank">Entrar como Principal</a><br/>
                    <a href={`https://assembleia.agenciaiter.com.br/?room=${item.room}&nome=${localUser_?.nome}&type=audience`} target="_blank">Entrar como Participante</a></td>*/}
                        <td className="text-right">
                          {/*<Button
                            color="primary"
                            round
                            className="mr-1"
                            placeholder="Editar"
                            onClick={() => { setCurrentItem(item); setIsOpen(true); }}>
                            <i className="fa fa-pen"></i>
                          </Button>*/}
                          <Button
                            color="danger"
                            round
                            className="mr-1"
                            onClick={() => { setModalConfirm(true); setCurrentItem(item); }}>
                            <i className="fa fa-trash"></i>
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default VideoChamadaScreen;
