import React from 'react';
import { Card, CardBody, CardTitle, CardText, CardImg, Row, Col } from 'reactstrap';

const Carteira = (props) => {
    return (
        <Card style={{ backgroundColor: 'lightgrey' }}>
            <Row>
                <CardImg top style={{ width: 120, height: 120, marginLeft: 30, marginTop: 30, border: 5 }} src={require("assets/img/default-avatar.png")} alt="QR Code" />
                <Col md="8" className='mt-5 ml-3'>
                    <CardTitle>
                        <strong>{props.nome}</strong>
                    </CardTitle>
                    <CardText>
                        <strong>CPF:</strong> {props.cpf}
                    </CardText>
                    <CardText>
                        <strong>RG:</strong> {props.rg} {"   "}
                        <strong>Telefone:</strong> {props.telefone}
                    </CardText>
                </Col>

            </Row>

            <CardBody>
                <Row>
                    <CardImg style={{ width: 130, height: 130, marginLeft: 20, marginTop: 30, border: 5 }} src={require("assets/img/codeqr.jpg")} />
                    <Col md="6" className='mt-4 '>
                        <CardText>
                            <strong>Número de sócio:</strong> {props.numero_socio}
                        </CardText>
                        <CardText>
                            <strong>Data de Nascimento:</strong> {props.birthdate}
                        </CardText>
                        <CardText>
                            <strong>Data de Validade:</strong> {props.expiryDate}
                        </CardText>
                    </Col>
                </Row>
            </CardBody>
        </Card>
    );
}

export default Carteira;
