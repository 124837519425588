import React, { useState, useEffect } from "react";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  FormGroup,
  Form,
  Input,
  Row,
  Col,
  Modal,
  Table,
  Label,
  CardSubtitle,
  CardImg,
  CardText
} from "reactstrap";

import NotificationAlert from "react-notification-alert";

import defaultService from "../../services/defaultService";
import ModalConfirm from "components/ModalConfirm";
import SunEditor from "suneditor-react";
import 'suneditor/dist/css/suneditor.min.css';
import { set } from "lodash";

const table = "noticias";
const urlBase = 'https://sindicato.agenciaiter.com.br/api/public/';
function VerNoticiasScreen({ nameRoute }) {

  const [isOpen, setIsOpen] = useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [items, setUsers] = useState([]);
  const [currentItem, setCurrentItem] = useState({});
  const [localUser, setLocalUser] = useState({});
  const notificationAlert = React.useRef();


  useEffect(() => {
    listItems();
  }, []);

  const listItems = async () => {
    const localUser_ = JSON.parse(localStorage?.getItem("user"));
    setLocalUser(localUser_);

    var items_ = await defaultService.list(table);

    items_?.[0]?.id && setUsers(items_);

    //var items_ = await defaultService.list("clinicas");
    //setClinicas(items_);
  }

  const onSubmit = async (e) => {
    e.preventDefault();

    const form = {};

    form.table = table;

    for (let index = 0; index < e.target.length; index++) {
      if (e.target[index].name)
        form[e.target[index].name] = e.target[index].value;
    }

    if (currentItem?.id) {
      editItem(form);
      return;
    }

    const resp = await defaultService.create(form);
    setIsOpen(false);

    resp?.status == 200 ?
      notify("tr", 2, 'Cadastro feito com sucesso!')
      : notify("tr", 3, 'Erro ao cadastrar!');

    listItems();

  }

  const deleteItem = async (e) => {
    setModalConfirm(false);

    if (e != "sim") {
      setCurrentItem({});
      return
    }

    const resp = await defaultService.delete(currentItem.id, table);

    resp?.status == 200 ?
      notify("tr", 2, "Deletado com sucesso!")
      : notify("tr", 3, 'Erro ao deletar!');

    setCurrentItem({});

    listItems();
  }

  const editItem = async (userData) => {

    const resp = await defaultService.update(currentItem?.id, userData);
    resp?.status == 200 ?
      notify("tr", 2, "Atualizado com sucesso!")
      : notify("tr", 3, 'Erro ao atalizar!');

    setCurrentItem({});
    setIsOpen(false);

    listItems();
  }

  const notify = (place, color, message) => {
    var type;
    switch (color) {
      case 1:
        type = "primary";
        break;
      case 2:
        type = "success";
        break;
      case 3:
        type = "danger";
        break;
      case 4:
        type = "warning";
        break;
      case 5:
        type = "info";
        break;
      default:
        break;
    }
    var options = {};
    options = {
      place: place,
      message: (
        <div><div>{message}</div></div>
      ),
      type: type,
      icon: "nc-icon nc-bell-55",
      autoDismiss: 7,
    };
    notificationAlert.current.notificationAlert(options);
  };

  return (
    <>
      <div className="content">
        <NotificationAlert ref={notificationAlert} />
        <ModalConfirm isOpen={modalConfirm} toggle={deleteItem} />
        <Row>
          <Col md="12">
            <Modal
              size="xl"
              isOpen={isOpen}
              toggle={() => { setIsOpen(false); setCurrentItem({}) }} >
              <Card className="mb-0">
                <CardImg
                  top
                  width="100%"
                  height={"300px"}
                  style={{ objectFit: "cover" }}
                  src={`${urlBase}${currentItem?.imagem_destaque?.includes('[') && JSON?.parse(currentItem?.imagem_destaque)}`}
                />
                <h6
                  className="text-right pr-5 mt-3"
                  style={{ cursor: "pointer", position: "absolute", top: "10px", right: "10px" }}
                  onClick={() => { setIsOpen(false); setCurrentItem({}); }}>
                  Fechar
                </h6>
                <CardBody className="text-center">
                  <CardTitle tag={"h4"} className="text-center title">{currentItem?.nome}</CardTitle>
                  <CardText dangerouslySetInnerHTML={{ __html: currentItem?.conteudo }} />
                  {currentItem?.imagens?.includes('[') && <img
                    alt="..."
                    className="avatar border-gray"
                    style={{ width: "150px", height: "150px" }}
                    src={`${urlBase}${JSON?.parse(currentItem?.imagens)}`}
                  />}
                  {currentItem?.imagens2?.includes('[') && <img
                    alt="..."
                    className="avatar border-gray ml-5"
                    style={{ width: "150px", height: "150px" }}
                    src={`${urlBase}${JSON?.parse(currentItem?.imagens2)}`}
                  />}
                  {currentItem?.imagens3?.includes('[') && <img
                    alt="..."
                    className="avatar border-gray ml-5"
                    style={{ width: "150px", height: "150px" }}
                    src={`${urlBase}${JSON?.parse(currentItem?.imagens3)}`}
                  />}
                </CardBody>
              </Card>
            </Modal>
          </Col>

          <Col md="12">
            {items && items.map((item) => (

              <Card className="card-user mb-3" key={item?.id + 'news'} >
                <CardBody style={{ minHeight: "100px" }}>
                  <Row className="align-items-center">
                      <img
                        alt="..."
                        style={{ objectFit: "cover", height: 50, width: 50 }}
                        className="avatar border-gray ml-3 mt-2"
                        src={`${urlBase}${item?.imagem_destaque.includes('[') && JSON?.parse(item?.imagem_destaque)}`}
                      />
                      <div className="ml-3">
                        <a href="#item" onClick={(e) => { setIsOpen(true); setCurrentItem(item); }}>
                          <h5 className="title">{item?.nome}</h5>
                        </a>
                      </div>
                  </Row>
                </CardBody>
              </Card>)
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default VerNoticiasScreen;
